import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: 'AIzaSyCusETMByWGj2c__kVq7XhHFQR9ql_Xrz8',
    projectId: 'ism-maintenance',
    messagingSenderId: '225228503997',
    appId: '1:225228503997:android:d74b8c5079fbe658fbf0d4'
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection('users');
const jobsCollection = db.collection('jobs');
const reportsCollection = db.collection('reports');
const clientsCollection = db.collection('clients');
const objectsCollectionCroup = db.collectionGroup('objects');
const objectsCollection = db.collection('objects');
const elementsCollectionGroup = db.collectionGroup('elements');
const elementsCollection = db.collection('elements');

// export utils/refs
export {
    db,
    auth,
    usersCollection,
    jobsCollection,
    reportsCollection,
    clientsCollection,
    objectsCollectionCroup,
    objectsCollection,
    elementsCollectionGroup,
    elementsCollection
}

<template>
  <div class="loginBox b-radius--5 shadow--light bgColor--white bgColor--white flex flex--column flex--center__vert pad--25">
    <div class="flex flex--column width--100 mar--bottom__1">
      <label for="login_email" class="input--label">Email</label>
      <input id="login_email" type="text" class="input--text" v-model="email"/>
    </div>
    <div class="flex flex--column width--100 mar--bottom__2">
      <label for="login_password" class="input--label">Passwort</label>
      <input id="login_password" type="password" class="input--text" v-model="password"/>
    </div>
    <AccentButton title="Anmelden" full-width="true" :loading="loading" @onClick="login"/>
    <div class="mar--top__2 font--s16 flex flex--center clickable" @click="forgotPassword">
      Passwort vergessen?
    </div>
  </div>
</template>

<script>
import AccentButton from '@/components/Buttons/AccentButton';
export default {
  name: 'LoginBox',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  components: {AccentButton},
  methods: {
    async login() {
      this.loading = true;
      await this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
      });
      this.loading = false;
    },
    async forgotPassword() {
      if (this.email && this.email !== '') {
        await this.$store.dispatch('forgotPassword', {
          email: this.email,
        });
      } else {
        this.$store.commit('addToast', {
          type: 'warning',
          title: 'Bitte gib deine Email ein.'
        });
      }

    },
  },
}
</script>

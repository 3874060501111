<template>
    <div class="width--100 height--100 flex--column">
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'email',
                        asc: 1,
                    })">
                        A - Z
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'email',
                        asc: -1,
                    })">
                        Z - A
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: 1,
                    })">
                        Neueste Zuerst
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: -1,
                    })">
                        Älteste Zuerst
                    </div>
                </PopUp>
            </div>
        </transition>
        <div class="pad--2 sticky top--0 front">
            <div class="flex flex--space__hor flex--center__vert pad--2 bgColor--white shadow--light b-radius--5">
                <div class="flex filters flex--grow">
                    <v-select :options="options" :clearable="false" v-model="selectedOverviewType" class="filters--item input--selection mar--right__1"/>
                    <v-select placeholder="Kunde" v-if="selectedOverviewType === 'Objekte' || selectedOverviewType === 'Elemente'" v-model="selectedClientFilter" label="name" :options="clients" class="filters--item input--selection mar--right__1"/>
                    <v-select placeholder="Objekt" v-if="selectedOverviewType === 'Elemente' && selectedClientFilter" v-model="selectedObjectFilter" :options="objects" label="name" class="filters--item input--selection mar--right__1"/>
                </div>
<!--                <div class="flex&#45;&#45;grow" />-->
<!--                <div class="mar&#45;&#45;left__1">-->
<!--                    <ActionButton icon="swap-vertical" light="true" @onClick="toggleMainPopup" v-click-outside="() => { this.showMainPopup = false }"/>-->
<!--                </div>-->
                <div class="mar--left__1">
                    <ActionButton icon="add" accent="true" @onClick="$router.push('/overview/new/0')"/>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div class="flex flex--center width--100 height--50" key="loader" v-if="isLoading">
                <loader :loading="true" color="#4FBBC7" size="50px" />
            </div>
            <div class="flex flex--column list overflowAuto" key="list" v-if="!isLoading">
                    <div class="flex flex--column" key="content" v-if="selectedOverviewType === 'Mitarbeiter'">
                            <UserListItem v-for="entry in users" :key="entry.id" :user="entry" />
                    </div>
                    <div class="flex flex--column" key="content" v-if="selectedOverviewType === 'Kunden'">
                            <ClientListItem v-for="entry in clients" :key="entry.id" :client="entry" />
                    </div>
                    <div class="flex flex--column" key="content" v-if="selectedOverviewType === 'Objekte'">
                        <ObjectListItem v-for="entry in objects" :key="entry.id" :object="entry" />
                    </div>
                    <div class="flex flex--column" key="content" v-if="selectedOverviewType === 'Elemente'">
                        <ElementListItem v-for="entry in elements" :key="entry.id" :element="entry" />
                    </div>
                    <div class="width--100 pad--2 flex flex--center" :key="'footer'" v-if="selectedOverviewType !== 'Kunden' && selectedOverviewType !== 'Mitarbeiter'">
                        <ActionButton icon="chevron-back" class="mar--right__1" @onClick="pageBack"/>
                        <div class="font--w700">
                            {{ (page - 1) * 50 + 1}} bis {{ page * 50 + 1}}
                        </div>
                        <ActionButton icon="chevron-forward" class="mar--left__1" @onClick="pageForward"/>
                    </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/Buttons/ActionButton';
    import PopUp from "../../components/Modals/PopUp";
    import UserListItem from "@/components/ListItems/UserListItem";
    import ClientListItem from "@/components/ListItems/ClientListItem";
    import ObjectListItem from "@/components/ListItems/ObjectListItem";
    import ElementListItem from "@/components/ListItems/ElementListItem";
    export default {
        name: 'OverviewView',
        components: {ElementListItem, ObjectListItem, ClientListItem, UserListItem, PopUp, ActionButton, vSelect},
        data() {
            return {
                options: ['Mitarbeiter','Kunden', 'Objekte', 'Elemente'],
                isLoading: false,

                posX: null,
                posY: null,

                showMainPopup: false,
                page: 1,

                searchString: null,
            };
        },
        computed: {
            users: {
                get() {
                    return this.$store.getters.users;
                },
                set(val) {
                    this.$store.commit('setUsers', val);
                }
            },
            clients: {
                get() {
                    console.log(this.$store.getters.clients);
                    return this.$store.getters.clients;
                },
                set(val) {
                    this.$store.commit('setClients', val);
                }
            },
            objects: {
                get() {
                    return this.$store.getters.objects;
                },
                set(val) {
                    this.$store.commit('setObjects', val);
                }
            },
            elements: {
                get() {
                    console.log(this.$store.getters.elements);
                    return this.$store.getters.elements;
                },
                set(val) {
                    this.$store.commit('setElements', val);
                }
            },
            selectedOverviewType: {
                get() {
                    return this.$store.getters.selectedOverviewType;
                },
                set(val) {
                    this.$store.commit('setOverviewType', val);
                }
            },
            selectedClientFilter: {
                get() {
                    return this.$store.getters.selectedClientFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedClientFilter', val);
                }
            },
            selectedObjectFilter: {
                get() {
                    return this.$store.getters.selectedObjectFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedObjectFilter', val);
                }
            },
        },
        methods: {
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;
                this.showMainPopup = !this.showMainPopup;
            },
            async pageBack() {
                if (this.page > 1) {
                    this.page--;
                }
            },
            async pageForward() {
                switch (this.selectedOverviewType) {
                    case 'Objekte':
                        if (this.objects.length >= 50) {
                            this.page++;
                        }
                        break;
                    case 'Elemente':
                        if (this.elements.length >= 50) {
                            this.page++;
                        }
                        break;
                }
            },
            fetchOverviewItems(back) {
                this.isLoading = true;
                switch (this.selectedOverviewType) {
                    case 'Objekte':
                        this.$store.dispatch('fetchObjects', back);
                        break;
                    case 'Elemente':
                        this.$store.dispatch('fetchObjects', back);
                        this.$store.dispatch('fetchElements', back);
                        break;
                }
                this.isLoading = false;
            },
        },
        watch: {
            async selectedOverviewType() {
                this.$store.commit('setFirstDocument', null);
                this.$store.commit('setLastDocument', null);
                this.selectedObjectFilter = null;
                if (this.page === 1) {
                    this.isLoading = true;
                    await this.fetchOverviewItems(false);
                    this.isLoading = false;
                } else {
                    this.isLoading = true;
                    this.page = 1;
                }
            },
            async selectedClientFilter() {
                this.$store.commit('setFirstDocument', null);
                this.$store.commit('setLastDocument', null);
                this.selectedObjectFilter = null;
                if (this.page === 1) {
                    this.isLoading = true;
                    await this.fetchOverviewItems(false);
                    this.isLoading = false;
                } else {
                    this.isLoading = true;
                    this.page = 1;
                }
            },
            async page(newVal, oldVal) {
                console.log('Page Change');
                console.log(newVal < oldVal);
                await this.fetchOverviewItems(newVal < oldVal);
                this.isLoading = false;
            }
        },
        async mounted() {
            if (!this.selectedClientFilter) {
                this.selectedObjectFilter = null;
            }
            this.page = 1;
            this.$store.commit('setFirstDocument', null);
            this.$store.commit('setLastDocument', null);
            this.fetchOverviewItems(false);
        },
    }
</script>

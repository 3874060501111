<template>
    <div class="list--entry flex relative" @click="$router.push(`/jobs/${job.type}/${job.id}`)">
        <div class="width--100 height--100 grid list--entry__grid5 font--s14">
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Auftragsnummer
                </div>
                <div class="font--w700">
                    {{ job.title }}
                </div>
            </div>
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Status
                </div>
                <div class="font--w700">
                    {{ job.done ? 'Abgeschlossen' : 'Offen' }}
                </div>
            </div>
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Fortschritt
                </div>
                <div class="font--w700">
                    {{ progress }}%
                </div>
            </div>
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Typ
                </div>
                <div class="font--w700">
                    {{ title }}
                </div>
            </div>
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Ab Datum
                </div>
                <div class="font--w700">
                    {{ job.startDate.toDate() | dateFormat }}
                </div>
            </div>
            <div class="flex flex--center">
                <ActionButton icon="pencil" accent="true"/>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import ActionButton from "@/components/Buttons/ActionButton";
    export default {
        components: {ActionButton},
        props: {
            job: {
                type: Object,
                default: () => {},
            }
        },
        filters: {
            dateFormat(val) {
                return moment(val).format('DD.MM.yyyy');
            },
        },
        computed: {
            title() {
                switch (this.job.type) {
                    case 'maintenance':
                        return 'Wartung';
                    case 'repair':
                        return 'Reparatur';
                    default:
                        return 'Unbekannt';
                }
            },
            progress() {
                if (!this.job.completedElementIDs.length || !this.job.allElementIDs.length) return 0;
                return ((this.job.completedElementIDs.length / this.job.allElementIDs.length) * 100).toFixed(0);
            },
        },
    }
</script>

<style scoped>

</style>

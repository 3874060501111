import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ClickOutside from 'vue-click-outside';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { auth } from './services/firebase_setup';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'ion-icon',
];


Vue.component('loader', BounceLoader);
Vue.component('datepicker', Datepicker);


Vue.directive('ClickOutside', ClickOutside);


Vue.prototype.moment = moment;

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
});

let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
});

export default {
    setUserProfile(state, val) {
        state.userProfile = val;
    },
    setUsers(state, val) {
        state.users = val;
    },
    setReports(state, val) {
        state.reports = val;
    },
    setJobs(state, val) {
        state.jobs = val;
    },
    setClients(state, val) {
        state.clients = val;
    },
    setObjects(state, val) {
        state.objects = val;
    },
    setElements(state, val) {
        state.elements = val;
    },
    setOverviewType(state, val) {
        state.selectedOverviewType = val;
    },
    setJobType(state, val) {
        state.selectedJobType = val;
    },
    setStatus(state, val) {
        state.selectedStatus = val;
    },
    setSelectedClientFilter(state, val) {
        state.selectedClientFilter = val;
    },
    setSelectedObjectFilter(state, val) {
        state.selectedObjectFilter = val;
    },
    setLastDocument(state, val) {
        state.lastDocument = val;
    },
    setFirstDocument(state, val) {
        state.firstDocument = val;
    },
    addToast(state, val) {
        state.toasts.push({
            id: Date.now().toString(),
            type: val.type,
            title: val.title,
        });
    },
    deleteToast(state, val) {
        state.toasts = state.toasts.filter(el => el.id !== val);
    },

    // SORT MUTATION ------------------------------------------------------------

    // sortUsers(state, {property, asc}) {
    //     const compareFunction = (a, b) => {
    //         const propA = a[property].toUpperCase();
    //         const propB = b[property].toUpperCase();
    //         let comparison = 0;
    //         if (propA > propB) {
    //             asc ? comparison = 1 : comparison = -1;
    //         } else if (propA < propB) {
    //             asc ? comparison = -1 : comparison = 1;
    //         }
    //         return comparison;
    //     };
    //     state.users = state.users.sort(compareFunction);
    // }
}

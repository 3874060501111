import * as fb from "../services/firebase_setup";
import {clientsCollection} from "../services/firebase_setup";
import firebase from "firebase";
import router from '../router';
import axios from 'axios';
import _ from 'lodash';
import {jobsCollection} from "../services/firebase_setup";
import store from '../store';

const BASE_URL = 'https://us-central1-ism-maintenance.cloudfunctions.net/';
const BASE_API = 'https://api.ismberlin.app/';

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error.response.status);
    if (error.response.status === 401) {
        store.dispatch('logout');
    }
    if (error.response.data) {
        store.commit('addToast', {
            type: 'error',
            title: error.response.data.message,
        })
    } else {
        store.commit('addToast', {
            type: 'error',
            title: 'Es ist ein Fehler aufgetreten.'
        })
    }
    return Promise.reject(error);
});

export default {
    // HELPER ACTION --------------------------------------------------------------------------------------
    async populateContent({getters, commit}, {collection, back, initial}) {
        let snapshot;
        if (back) {
            snapshot = await collection.limit(50).endAt(getters.firstDocument.ref.path).get();
        } else if (!getters.firstDocument || initial) {
            snapshot = await collection.limit(50).get();
        } else {
            snapshot = await collection.limit(50).startAt(getters.lastDocument.ref.path).get();
        }
        let returnArray = [];

        snapshot.docs.forEach((doc, index) => {
            if (index === 0) {
                commit('setFirstDocument', _.cloneDeep(doc));
            }
            if (index === snapshot.docs.length - 1) {
                commit('setLastDocument', _.cloneDeep(doc));
            }
            let data = doc.data();
            data.id = doc.id;
            data.reference = _.cloneDeep(doc.ref);
            returnArray.push(data);
        });
        return returnArray;
    },

    // OVERVIEW ACTIONS ------------------------------------------------------------------------------------
    async fetchObjects({commit, dispatch, getters}, back) {
        let values = [];
        let baseQuery = fb.objectsCollectionCroup.orderBy(firebase.firestore.FieldPath.documentId());
        if (getters.selectedClientFilter) {
            baseQuery = baseQuery.where('clientID', '==', getters.selectedClientFilter.id);
        }
        values = await dispatch('populateContent', {
            collection: baseQuery,
            back,
            initial: true,
        });
        commit('setObjects', values);
    },
    async fetchElements({commit, dispatch, getters}, back) {
        let values = [];
        let baseQuery = fb.elementsCollectionGroup.orderBy(firebase.firestore.FieldPath.documentId());
        if (getters.selectedClientFilter) {
            baseQuery = baseQuery.where('clientID', '==', getters.selectedClientFilter.id);
        }
        if (getters.selectedObjectFilter) {
            baseQuery = baseQuery.where('objectID', '==', getters.selectedObjectFilter.id);
        }
        values = await dispatch('populateContent', {
            collection: baseQuery,
            back,
        });
        commit('setElements', values);
    },

    async fetchJobs({commit, dispatch, getters}, back) {
        let values = [];
        // let baseQuery = fb.jobsCollection;
        let baseQuery = fb.jobsCollection.orderBy('startDate', "desc");
        if (getters.selectedClientFilter) {
            baseQuery = baseQuery.where('clientID', '==', getters.selectedClientFilter.id);
        }
        if (getters.selectedJobType) {
            baseQuery = baseQuery.where('type', '==', getters.selectedJobType.value );
        }
        if (getters.selectedStatus) {
            baseQuery = baseQuery.where('done', '==', getters.selectedStatus.value );
        }
        values = await dispatch('populateContent', {
            collection: baseQuery,
            back,
        });
        commit('setJobs', values);
    },

    async fetchReports({commit, dispatch, getters}, back) {
        let values = [];
        let baseQuery = fb.reportsCollection;
        // let baseQuery = fb.reportsCollection.orderBy('startDate', "desc");
        if (getters.selectedClientFilter) {
            baseQuery = baseQuery.where('jobID', '==', getters.selectedClientFilter.id);
        }
        if (getters.selectedObjectFilter) {
            baseQuery = baseQuery.where('objectID', '==', getters.selectedObjectFilter.id );
        }
        values = await dispatch('populateContent', {
            collection: baseQuery,
            back,
        });
        commit('setReports', values);
    },

    async fetchReport({commit, dispatch}, jobID) {
        let values = [];
        let baseQuery = fb.reportsCollection.orderBy('generatedDate', 'desc').where('jobID', '==', jobID).limit(1);
        values = await dispatch('populateContent', {
            collection: baseQuery,
            back: false,
            initial: true,
        });
        console.log(values);
        commit('setReports', values);
    },

    async editUser({commit}, user) {
        await fb.usersCollection.doc(user.id).update({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'roles': user.roles,
        });
        commit('addToast', {
            type: 'success',
            title: 'Nutzer erfolgreich bearbeitet.'
        });
        router.go(-1);
    },
    async editClient({commit}, client) {
        await client.reference.update({
            'name': client.name,
        });
        commit('addToast', {
            type: 'success',
            title: 'Kunde erfolgreich bearbeitet.'
        });
        router.go(-1);
    },
    async editObject({commit}, object) {
        await object.reference.update({
            'address': object.address,
            'name': object.name,
            'rooms': object.rooms ?? [],
        });
        commit('addToast', {
            type: 'success',
            title: 'Objekt erfolgreich bearbeitet.'
        });
        router.go(-1);
    },
    async editElement({commit}, element) {
        await element.reference.update({
            'floor': element.floor,
            'room': element.room,
            'position': element.position,
            'element': element.element,
            'type': element.type,
            'material': element.material,
            'manufacturer': element.manufacturer,
        });
        commit('addToast', {
            type: 'success',
            title: 'Element erfolgreich bearbeitet.'
        });
        router.go(-1);
    },
    async editJob({commit}, { job, assignees }) {
        await job.reference.update({
            'warning': job.warning,
            'expected': job.expected,
            'title': job.title,
            'done': job.done,
            'endDate': job.done && !job.endDate ? Date() : !job.done ? null : job.endDate,
            'assigneesIDs': assignees.map(el => el.id),
        });
        commit('addToast', {
            type: 'success',
            title: 'Auftrag erfolgreich bearbeitet.'
        });
        router.go(-1);
    },
    async deleteClient({ commit }, client) {
        await fb.clientsCollection.doc(client.id).delete();
        commit('addToast', {
            type: 'success',
            title: 'Kunde erfolgreich gelöscht.'
        });
        router.go(-1);
    },
    async deleteObject({ commit }, object) {
        await fb.clientsCollection.doc(object.clientID).collection('objects').doc(object.id).delete();
        commit('addToast', {
            type: 'success',
            title: 'Objekt erfolgreich gelöscht.'
        });
        router.go(-1);
    },
    async deleteElement({ commit }, element) {
        await fb.elementsCollectionGroup.where('clientID', '==', element.clientID).where('objectID', '==', element.objectID).doc(element.id).delete();
        router.go(-1);
        commit('addToast', {
            type: 'success',
            title: 'Element erfolgreich gelöscht.'
        });
    },
    async createUser({commit}, user) {
        const token = await fb.auth.currentUser.getIdToken();
        await axios.post(BASE_URL + 'createUser', user, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        commit('addToast', {
            type: 'success',
            title: 'Nutzer erfolgreich erstellt.'
        });
        router.go(-1);
    },
    async deleteJob({ commit }, job) {
        await fb.jobsCollection.doc(job.id).delete();
        commit('addToast', {
            type: 'success',
            title: 'Auftrag erfolgreich gelöscht.'
        });
        router.go(-1);
    },

    async createClient({commit}, client) {
        await clientsCollection.add(client);
        commit('addToast', {
            type: 'success',
            title: 'Kunde erfolgreich erstellt.'
        });
        router.go(-1);
    },

    async createObject({commit}, {object, client}) {
        console.log({
            ...object,
            clientID: client.id,
        });
        await clientsCollection.doc(client.id).collection('objects').add({
            ...object,
            clientID: client.id,
        });
        commit('addToast', {
            type: 'success',
            title: 'Objekt erfolgreich erstellt.'
        });
        router.go(-1);
    },

    async createJob({commit, getters}, {job, assignees, elements, object, type}) {
        await jobsCollection.add({
            ...job,
            warning: true,
            assigneesIDs: assignees.map(el => el.id),
            allElementIDs: elements.map(el => el.id),
            clientID: getters.selectedClientFilter.id,
            objectID: object.id,
            type: type,
        });
        commit('addToast', {
            type: 'success',
            title: 'Auftrag erfolgreich erstellt.'
        });
        router.go(-1);
    },

    async createReport({commit}, jobID) {
        console.log(jobID);
        const token = await fb.auth.currentUser.getIdToken();
        await axios.post(BASE_API + 'reports?type=csv', {
            type: 'csv',
            jobID: jobID,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        commit('addToast', {
            type: 'success',
            title: 'Bericht erfolgreich erstellt.'
        });
    },

    async downloadReport({commit}, jobID) {
        const token = await fb.auth.currentUser.getIdToken();
        const response = await axios.get(BASE_API + 'reports/' + jobID, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const fileURL = window.URL.createObjectURL(new Blob([response.data]), {
            type: 'application/vnd.ms-excel'
        });
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        console.log(response.headers);
        fileLink.setAttribute('download', `ISM_Wartungsbericht_${jobID}.xlsm`);
        document.body.appendChild(fileLink);

        fileLink.click();
        commit('addToast', {
            type: 'success',
            title: 'Bericht erfolgreich erstellt.'
        });
        router.go(-1);
    },

    // AUTHENTICATION ACTIONS -------------------------------------------------------------------------------
    async login({dispatch}, form) {
        const {user} = await fb.auth.signInWithEmailAndPassword(form.email, form.password);
        dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile({commit, getters}, user) {
        const userProfile = await fb.usersCollection.doc(user.uid).get();
        commit('setUserProfile', userProfile.data());
        if (!getters.userProfile.roles.admin) {
            commit('addToast', {
                type: 'warning',
                title: 'Du bist kein Administrator',
            });
            await fb.auth.signOut();
        } else if (getters.userProfile.roles.admin && router.currentRoute.path === '/login') {
            router.push('/')
        }
        if (getters.userProfile.roles.admin) {
            fb.clientsCollection.orderBy(firebase.firestore.FieldPath.documentId()).onSnapshot(snapshot => {
                let clients = [];

                snapshot.docs.forEach(doc => {
                    let client = doc.data();
                    client.id = doc.id;

                    clients.push(client)
                });
                commit('setClients', clients)
            });

            fb.usersCollection.orderBy(firebase.firestore.FieldPath.documentId()).onSnapshot(snapshot => {
                let users = [];

                snapshot.docs.forEach(doc => {
                    let user = doc.data();
                    user.id = doc.id;

                    users.push(user)
                });
                commit('setUsers', users)
            });
        }
    },
    async forgotPassword({commit}, email) {
        const response = await fb.auth.sendPasswordResetEmail(email).catch(() => {
            commit('addToast', {
                type: 'warning',
                title: 'Etwas ist schiefgelaufen',
            })
        });
        if (response) {
            commit('addToast', {
                type: 'success',
                title: 'Bitte schau in deine Emails',
            })
        }
    },
    async logout({commit}) {
        await fb.auth.signOut();
        commit('setUserProfile', null);
        commit('addToast', {
            type: 'success',
            title: 'Erfolgreich ausgeloggt',
        });
        router.push('/login');
    },
}

import { render, staticRenderFns } from "./ObjectListItem.vue?vue&type=template&id=80716fbe&scoped=true&"
import script from "./ObjectListItem.vue?vue&type=script&lang=js&"
export * from "./ObjectListItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80716fbe",
  null
  
)

export default component.exports
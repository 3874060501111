export default {
    userProfile: null,
    toasts: [],
    users: [],
    clients: [],
    reports: [],
    jobs: [],
    objects: [],
    elements: [],

    firstDocument: null,
    lastDocument: null,

    selectedOverviewType: 'Mitarbeiter',
    selectedClientFilter: null,
    selectedObjectFilter: null,

    selectedJobType: null,
    selectedStatus: null,
}

<template>
    <div class="width--100 height--100 flex--column">
        <div class="pad--2 sticky top--0 front">
            <div class="flex flex--space__hor flex--center__vert pad--2 bgColor--white shadow--light b-radius--5">
                <div class="flex filters flex--grow">
                    <v-select v-model="selectedClientFilter" placeholder="Kunde" label="name" :options="clients" class="filters--item input--selection mar--right__1"/>
                    <v-select v-if="selectedClientFilter" v-model="selectedObjectFilter" placeholder="Object" label="name" :options="objects" class="filters--item input--selection mar--right__1"/>
                </div>
                <div class="mar--left__1">
                    <ActionButton icon="add" accent="true" @onClick="$router.push('/reports/new/0')"/>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div class="flex flex--center width--100 height--50" key="loader" v-if="isLoading">
                <loader :loading="true" color="#4FBBC7" size="50px" />
            </div>
            <div class="flex flex--column list overflowAuto" key="list" v-else>
                <transition-group name="list">
                    <ReportListItem v-for="entry in reports" :key="entry.id" :report="entry" />
                    <div class="width--100 pad--2 flex flex--center" :key="'footer'">
                        <ActionButton icon="chevron-back" class="mar--right__1" @onClick="pageBack"/>
                        <div class="font--w700">
                            {{ (page - 1) * 50}} bis {{ page * 50}}
                        </div>
                        <ActionButton icon="chevron-forward" class="mar--left__1" @onClick="pageForward"/>
                    </div>
                </transition-group>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/Buttons/ActionButton';
    import ReportListItem from "@/components/ListItems/ReportListItem";
    export default {
        name: 'ReportsView',
        components: {ReportListItem, ActionButton, vSelect},
        data() {
            return {
                isLoading: false,
                page: 1,
            };
        },
        computed: {
            reports: {
                get() {
                    return this.$store.getters.reports;
                },
                set(val) {
                    this.$store.commit('setReports', val);
                }
            },
            clients: {
                get() {
                    return this.$store.getters.clients;
                },
                set(val) {
                    this.$store.commit('setClients', val);
                }
            },
            objects: {
                get() {
                    return this.$store.getters.objects;
                },
                set(val) {
                    this.$store.commit('setObjects', val);
                }
            },
            selectedClientFilter: {
                get() {
                    return this.$store.getters.selectedClientFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedClientFilter', val);
                }
            },
            selectedObjectFilter: {
                get() {
                    return this.$store.getters.selectedObjectFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedObjectFilter', val);
                }
            },
        },
        methods: {
            async pageBack() {
                if (this.page > 1) {
                    this.page--;
                }
            },
            async pageForward() {
                if (this.reports.length >= 50) {
                    this.page++;
                }
            },
            async fetchReportItems(back) {
                this.isLoading = true;
                await this.$store.dispatch('fetchReports', back);
                this.isLoading = false;
            },
            async reloadContent() {
                this.$store.commit('setFirstDocument', null);
                this.$store.commit('setLastDocument', null);
                if (this.page === 1) {
                    this.isLoading = true;
                    await this.fetchReportItems(false);
                    this.isLoading = false;
                } else {
                    this.isLoading = true;
                    this.page = 1;
                }
            },
        },
        watch: {
            async selectedClientFilter(newVal) {
                if (newVal) await this.$store.dispatch('fetchObjects', false);
                this.reloadContent();
            },
            async selectedObjectFilter() {
                this.reloadContent();
            },
            async page(newVal, oldVal) {
                await this.fetchReportItems(newVal < oldVal);
                this.isLoading = false;
            }
        },
        async mounted() {
            if (!this.selectedClientFilter) {
                this.selectedObjectFilter = null;
            }
            this.page = 1;
            this.fetchReportItems(false);
        },
    }
</script>

<template>
    <div class="width--100 height--100 flex flex--center">
        <div class="grid landingView">
            <div class="bgColor--primary shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/overview')">
                <ion-icon name="albums" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Übersicht
                </div>
            </div>
            <div class="bgColor--primary shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/jobs')">
                <ion-icon name="hammer" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Aufträge
                </div>
            </div>
            <div class="bgColor--grey shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$store.dispatch('logout')">
                <ion-icon name="log-out-outline" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Abmelden
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'LandingView',
    };
</script>

<template>
    <div class="width--100 height--100 flex--column">
<!--        <transition name="fade">-->
<!--            <div class="absolute popup&#45;&#45;anchor" v-if="showMainPopup">-->
<!--                <PopUp :posX="posX" :posY="posY">-->
<!--                    <div class="popup&#45;&#45;item pad&#45;&#45;2 clickable font&#45;&#45;s16" @click="sort({-->
<!--                        attribute: 'title',-->
<!--                        asc: 1,-->
<!--                    })">-->
<!--                        A - Z-->
<!--                    </div>-->
<!--                    <div class="popup&#45;&#45;item pad&#45;&#45;2 clickable font&#45;&#45;s16" @click="sort({-->
<!--                        attribute: 'title',-->
<!--                        asc: -1,-->
<!--                    })">-->
<!--                        Z - A-->
<!--                    </div>-->
<!--                    <div class="popup&#45;&#45;item pad&#45;&#45;2 clickable font&#45;&#45;s16" @click="sort({-->
<!--                        attribute: 'createdAt',-->
<!--                        asc: 1,-->
<!--                    })">-->
<!--                        Neueste Zuerst-->
<!--                    </div>-->
<!--                    <div class="popup&#45;&#45;item pad&#45;&#45;2 clickable font&#45;&#45;s16" @click="sort({-->
<!--                        attribute: 'createdAt',-->
<!--                        asc: -1,-->
<!--                    })">-->
<!--                        Älteste Zuerst-->
<!--                    </div>-->
<!--                </PopUp>-->
<!--            </div>-->
<!--        </transition>-->
        <div class="pad--2 sticky top--0 front">
            <div class="flex flex--space__hor flex--center__vert pad--2 bgColor--white shadow--light b-radius--5">
                <div class="flex filters flex--grow">
                    <v-select :options="options" placeholder="Auftragstyp" v-model="selectedJobType" class="filters--item input--selection mar--right__1"/>
                    <v-select :options="options2" placeholder="Status" v-model="selectedStatus" class="filters--item input--selection mar--right__1"/>
                    <v-select v-model="selectedClientFilter" placeholder="Kunde" label="name" :options="clients" class="filters--item input--selection mar--right__1"/>
                </div>
<!--                <div class="mar&#45;&#45;left__1">-->
<!--                    <ActionButton icon="swap-vertical" light="true" @onClick="toggleMainPopup" v-click-outside="() => { this.showMainPopup = false }"/>-->
<!--                </div>-->
                <div class="mar--left__1">
                    <ActionButton icon="add" accent="true" @onClick="$router.push('/jobs/new/0')"/>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div class="flex flex--center width--100 height--50" key="loader" v-if="isLoading">
                <loader :loading="true" color="#4FBBC7" size="50px" />
            </div>
            <div class="flex flex--column list overflowAuto" key="list" v-else>
                <transition-group name="list">
                    <JobListItem v-for="entry in jobs" :key="entry.id" :job="entry" />
                    <div class="width--100 pad--2 flex flex--center" :key="'footer'">
                        <ActionButton icon="chevron-back" class="mar--right__1" @onClick="pageBack"/>
                        <div class="font--w700">
                            {{ (page - 1) * 50 + 1}} bis {{ page * 50 + 1}}
                        </div>
                        <ActionButton icon="chevron-forward" class="mar--left__1" @onClick="pageForward"/>
                    </div>
                </transition-group>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/Buttons/ActionButton';
    import JobListItem from "@/components/ListItems/JobListItem";
    export default {
        name: 'JobsView',
        components: {JobListItem, ActionButton, vSelect},
        data() {
            return {
                options: [{
                    label: 'Wartungsaufträge',
                    value: 'maintenance',
                }, {
                    label: 'Reparaturaufträge',
                    value: 'repair',
                }],
                options2: [{
                    label: 'Abgeschlossen',
                    value: true,
                }, {
                    label: 'Offen',
                    value: false,
                }],

                posX: null,
                posY: null,

                showMainPopup: false,
                isLoading: false,
                page: 1,

                searchString: null,
            };
        },
        computed: {
            jobs: {
                get() {
                    return this.$store.getters.jobs;
                },
                set(val) {
                    this.$store.commit('setJobs', val);
                }
            },
            clients: {
                get() {
                    return this.$store.getters.clients;
                },
                set(val) {
                    this.$store.commit('setClients', val);
                }
            },
            selectedClientFilter: {
                get() {
                    return this.$store.getters.selectedClientFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedClientFilter', val);
                }
            },
            selectedJobType: {
                get() {
                    return this.$store.getters.selectedJobType;
                },
                set(val) {
                    this.$store.commit('setJobType', val);
                }
            },
            selectedStatus: {
                get() {
                    return this.$store.getters.selectedStatus;
                },
                set(val) {
                    this.$store.commit('setStatus', val);
                }
            },
        },
        methods: {
            async pageBack() {
                if (this.page > 1) {
                    this.page--;
                }
            },
            async pageForward() {
                if (this.jobs.length >= 50) {
                    this.page++;
                }
            },
            async fetchJobItems(back) {
                this.isLoading = true;
                await this.$store.dispatch('fetchJobs', back);
                this.isLoading = false;
            },
            async reloadContent() {
                this.$store.commit('setFirstDocument', null);
                this.$store.commit('setLastDocument', null);
                if (this.page === 1) {
                    this.isLoading = true;
                    await this.fetchJobItems(false);
                    this.isLoading = false;
                } else {
                    this.isLoading = true;
                    this.page = 1;
                }
            },
        },
        watch: {
            async selectedClientFilter() {
                this.reloadContent();
            },
            async selectedJobType() {
                this.reloadContent();
            },
            async selectedStatus() {
                this.reloadContent();
            },
            async page(newVal, oldVal) {
                await this.fetchJobItems(newVal < oldVal);
                this.isLoading = false;
            }
        },
        async mounted() {
            this.$store.commit('setFirstDocument', null);
            this.$store.commit('setLastDocument', null);
            this.page = 1;
            this.fetchJobItems(false);
        },
    }
</script>

<template>
    <div class="list--entry flex relative" @click="$router.push(`/overview/object/${object.id}`)">
        <div class="width--100 height--100 grid list--entry__grid2 font--s14">
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Name
                </div>
                <div class="font--w700">
                    {{ object.name }}
                </div>
            </div>
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Adresse
                </div>
                <div class="font--w700">
                    {{ object.address.city }}
                </div>
            </div>
            <div class="flex flex--center">
                <ActionButton icon="pencil" accent="true"/>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionButton from "@/components/Buttons/ActionButton";
    export default {
        components: {ActionButton},
        props: {
            object: {
                type: Object,
                default: () => {},
            }
        }
    }
</script>

<style scoped>

</style>

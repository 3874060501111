export default {
    userProfile: state => state.userProfile,
    toasts: state => state.toasts,
    users: state => state.users,
    reports: state => state.reports,
    jobs: state => state.jobs,
    objects: state => state.objects,
    clients: state => state.clients,
    elements: state => state.elements,
    lastDocument: state => state.lastDocument,
    firstDocument: state => state.firstDocument,
    selectedOverviewType: state => state.selectedOverviewType,
    selectedJobType: state => state.selectedJobType,
    selectedStatus: state => state.selectedStatus,
    selectedClientFilter: state => state.selectedClientFilter,
    selectedObjectFilter: state => state.selectedObjectFilter,
}

<template>
    <div class="list--entry flex relative" @click="$router.push(`/reports/report/${report.id}`)">
        <div class="width--100 height--100 grid list--entry__grid2 font--s14">
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Status
                </div>
                <div class="flex">
                    <loader class="mar--right__1" :loading="true" color="#4FBBC7" size="15px" v-if="report.staus === 'processing'" />
                    <div class="font--w700">
                        {{ title }}
                    </div>
                </div>
            </div>
            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                    Ab Datum
                </div>
                <div class="font--w700">
                    {{ report.startDate.toDate() | dateFormat }}
                </div>
            </div>
            <div class="flex flex--center">
                <ActionButton icon="pencil" accent="true"/>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionButton from "@/components/Buttons/ActionButton";
    import moment from "moment";
    export default {
        components: {ActionButton},
        props: {
            report: {
                type: Object,
                default: () => {},
            }
        },
        filters: {
            dateFormat(val) {
                return moment(val).format('DD.MM.yyyy');
            },
        },
        computed: {
            title() {
                switch (this.report.status) {
                    case 'success':
                        return 'Fertig';
                    case 'processing':
                        return 'In Arbeit...';
                    default:
                        return 'Fehlgeschlagen';
                }
            },
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="flex flex--column flex--center__vert width--100 height--100">
        <div class="dashboard--header">
            <ActionButton @onClick="$router.push('/jobs')" icon="arrow-back"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow">
            <div class="flex flex--column mar--bottom__2" v-if="$route.params.type === 'maintenance'">
                <div class="flex flex--space__hor border--bottom__light mar--bottom__2 pad--bottom__1">
                    <h1>Wartungsbericht</h1>
                    <AccentButton :title="reports[0] ? 'Neu Generieren' : 'Generieren'" :loading="loading" @onClick="createReport" />
                </div>
                <div class="font--s14" v-if="!reports[0]">
                    Noch kein Wartungsbericht generiert.
                </div>
                <div class="font--s14" v-if="reports[0]">
                    <div class="flex flex--space__hor">
                        {{ reports[0].status === 'success' ? 'Erfolgreich generiert.' : reports[0].status === 'processing' ? 'Wird generiert... (dauert bis zu 1min)' : 'Generierung fehlgeschlagen.'}}
                        <ActionButton :accent="true" icon="cloud-download" @onClick="$store.dispatch('downloadReport', reports[0].id)" v-if="reports[0].status === 'success'" />
                        <div v-if="reports[0].status === 'processing'">
                            <loader :loading="true" color="#4FBBC7" :size="'25px'"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex border--bottom__light mar--bottom__2 pad--bottom__1" v-if="$route.params.type !== 'new'">
                <h1 class="mar--bottom__1">Auftragsdaten</h1>
            </div>
            <div class="input--label">Typ</div>
            <v-select :clearable="false" :disabled="$route.params.type !== 'new'" :options="options" class="input--selection width--100 mar--bottom__2"
                      v-model="type"/>

            <div class="flex--column width--100">
                <div class="flex flex--column" v-if="$route.params.type !== 'new'">
                    <div class="input--label">Kunde</div>
                    <v-select :clearable="false" :options="clients" :value="populateClient(content.clientID)" class="input--selection width--100 mar--bottom__2"
                              disabled
                              label="name"/>
                    <div class="input--label">Objekt</div>
                    <v-select :clearable="false" :options="objects" :value="populateObject(content.objectID)" class="input--selection width--100 mar--bottom__2"
                              disabled
                              label="name"/>
                </div>
                <div class="flex flex--column" v-else>
                    <div class="input--label">Kunde</div>
                    <v-select :clearable="false" :options="clients" class="input--selection width--100 mar--bottom__2" label="name"
                              v-model="selectedClientFilter"/>
                    <div class="input--label">Objekt</div>
                    <v-select :clearable="false" :disabled="!selectedClientFilter" :options="objects" class="input--selection width--100 mar--bottom__2"
                              label="name" v-model="selectedObject"/>

                </div>
                <div class="input--label">Monteure</div>
                <v-select :clearable="false" :multiple="true" :options="users" class="input--selection width--100 mar--bottom__2"
                          label="firstName" v-model="selectedAssignees">
                    <template #option="{firstName, lastName}">
                        <div>{{ `${firstName} ${lastName}` }}</div>
                    </template>
                    <template #selected-option="{firstName, lastName}">
                        <div>{{ `${firstName} ${lastName}` }}</div>
                    </template>
                </v-select>
                <label class="input--label" for="job_title">Auftragsnummer</label>
                <input class="input--text mar--bottom__2" id="job_title" v-model="content.title"/>
                <label class="input--label" for="job_elements">Zu wartende Elemente</label>
                <input type="number" class="input--text mar--bottom__2" id="job_elements" v-model="content.expected"/>
                <div class="input--label">Startdatum</div>
                <!--                <input id="job_date" class="input&#45;&#45;text mar&#45;&#45;bottom__2" disabled :value="content.startDate | dateFormat"/>-->
                <datepicker :disabled="$route.params.type !== 'new'"
                            class="input--selection width--100 mar--bottom__2" :format="dateFormatter" :language="de"
                            v-model="content.startDate"/>

                <div class="flex flex--column" v-if="$route.params.type !== 'new'">
                    <label class="input--label" for="job_progress">Fortschritt</label>
                    <input :value="`${progress}%` " class="input--text mar--bottom__2" disabled id="job_progress"/>
                    <label class="input--label" for="job_total">Gesamtzahl der Elemente</label>
                    <input :value="content.allElementIDs ? content.allElementIDs.length : null" class="input--text mar--bottom__2" disabled
                           id="job_total"/>
                    <label class="input--label" for="job_done">Gewartete Elemente</label>
                    <input :value="content.completedElementIDs ? content.completedElementIDs.length : null" class="input--text mar--bottom__2" disabled
                           id="job_done"/>
                </div>
                <div class="flex">
                    <div class="flex flex--column mar--right__2" v-if="$route.params.type !== 'new'">
                        <div class="input--label">Abgeschlossen</div>
                        <div class="flex">
                            <input class="input--checkbox" id="job_isdone" type="checkbox" v-model="content.done"/>
                            <label class="flex flex--center" for="job_isdone">
                                <ion-icon class="font--s3 color--primary flex flex--center" name="checkmark"/>
                            </label>
                        </div>
                    </div>
                    <div class="flex flex--column" v-if="$route.params.type !== 'new'">
                        <div class="input--label">Warnung anzeigen</div>
                        <div class="flex">
                            <input class="input--checkbox" id="job_warning" type="checkbox" v-model="content.warning"/>
                            <label class="flex flex--center" for="job_warning">
                                <ion-icon class="font--s3 color--primary flex flex--center" name="checkmark"/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mar--bottom__1">
                <AccentButton :loading="loading" @onClick="$route.params.type === 'new' ? create() : update()"
                              title="Speichern"/>
            </div>
            <div class="flex flex--end__hor">
                <AccentButton @onClick="deleteItem(false)" danger="true" title="Löschen" v-if="content"/>
            </div>
        </div>
        <transition mode="out-in" name="fade">
            <Modal @toggle="showDeleteConfirmation = false" title="Achtung! Wirklich löschen?"
                   v-if="showDeleteConfirmation">
                <div class="flex flex--column pad--2 width--100 height--100">
                    <div class="mar--bottom__1 font--s16 pad--1">
                        Durch das Löschen werden die Daten unwiederruflich gelöscht.
                    </div>
                    <div class="width--100 flex pad--1">
                        <AccentButton @onClick="showDeleteConfirmation = false" class="width--100" title="Nein"/>
                        <AccentButton @onClick="deleteItem(true)" danger="true" title="Löschen"/>
                    </div>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '../../components/Buttons/ActionButton';
    import AccentButton from '../../components/Buttons/AccentButton';
    import Modal from "../../components/Modals/Modal";
    import _ from 'lodash';
    import moment from "moment";
    import { de } from 'vuejs-datepicker/dist/locale';

    export default {
        name: 'JobDetails',
        components: {Modal, AccentButton, ActionButton, vSelect},
        data() {
            return {
                showDeleteConfirmation: false,
                type: null,
                content: {},
                loading: false,
                selectedClient: null,
                selectedObject: null,
                selectedAssignees: [],
                selectedElements: [],
                de: de,
            };
        },
        filters: {
            dateFormat(val) {
                if (!val) return null;
                return moment(val.toDate()).format('DD.MM.yyyy');
            },
        },
        computed: {
            options() {
                if (this.$route.params.type === 'new') {
                    return [{
                        label: 'Wartungsauftrag',
                        value: 'maintenance',
                    }, {
                        label: 'Reparaturauftrag',
                        value: 'repair',
                    }];
                } else {
                    return [];
                }
            },
            selectedClientFilter: {
                get() {
                    return this.$store.getters.selectedClientFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedClientFilter', val);
                }
            },
            jobs() {
                return this.$store.getters.jobs;
            },
            reports() {
                return this.$store.getters.reports;
            },
            users() {
                return this.$store.getters.users;
            },
            clients() {
                return this.$store.getters.clients;
            },
            objects() {
                return this.$store.getters.objects;
            },
            progress() {
                if (!this.content.completedElementIDs || !this.content.allElementIDs || !this.content.allElementIDs.length) return 0;
                return ((this.content.completedElementIDs.length / this.content.allElementIDs.length) * 100).toFixed(0) ?? 0;
            },
        },
        methods: {
            dateFormatter(val) {
                return moment(val).format('DD.MM.yyyy');
            },
            populateUser(val) {
                const user = _.cloneDeep(this.users.find((el) => el.id === val));
                return user ?? null;
            },
            populateClient(val) {
                const client = _.cloneDeep(this.clients.find((el) => el.id === val));
                return client ?? null;
            },
            populateObject(val) {
                const client = _.cloneDeep(this.objects.find((el) => el.id === val));
                return client ?? null;
            },
            async create() {
                this.loading = true;
                await this.$store.dispatch('createJob', {
                    job: this.content,
                    assignees: this.selectedAssignees ?? [],
                    elements: this.selectedElements,
                    object: this.selectedObject,
                    type: this.type.value,
                });
            },
            async createReport() {
                console.log(this.content);
                this.loading = true;
                await this.$store.dispatch('createReport', this.content.id);
                await this.$store.dispatch('fetchReport', this.content.id);
                this.loading = false;
            },
            async update() {
                this.loading = true;
                await this.$store.dispatch('editJob', {
                    job: this.content,
                    assignees: this.selectedAssignees,
                });
            },
            async deleteItem(confirmed) {
                if (!confirmed) {
                    this.showDeleteConfirmation = true;
                } else {
                    await this.$store.dispatch('deleteJob', this.content);
                    this.showDeleteConfirmation = false;
                }
            }
        },
        watch: {
            selectedClientFilter() {
                this.$store.dispatch('fetchObjects', false);
            },
        },
        async mounted() {
            let populatedAssignees;
            switch (this.$route.params.type) {
                case 'maintenance':
                    this.type = 'Wartungsauftrag';
                    this.content = _.cloneDeep(this.jobs.find((el) => el.id === this.$route.params.id));
                    if (!this.content) {
                        this.$router.push('/jobs');
                    }
                    this.selectedClientFilter = this.populateClient(this.content.clientID);
                    this.$store.dispatch('fetchObjects', false);
                    this.$store.dispatch('fetchReport', this.content.id);
                    populatedAssignees = this.content.assigneesIDs.map((el) => this.populateUser(el));
                    this.selectedAssignees = populatedAssignees.filter((el) => el != null);
                    this.content.startDate = this.content.startDate.toDate();
                    break;
                case 'repair':
                    this.type = 'Reparaturauftrag';
                    this.content = _.cloneDeep(this.jobs.find((el) => el.id === this.$route.params.id));
                    if (!this.content) {
                        this.$router.push('/jobs');
                    }
                    this.selectedClientFilter = this.populateClient(this.content.clientID);
                    this.$store.dispatch('fetchObjects', false);
                    this.$store.dispatch('fetchReport', this.content.id);
                    populatedAssignees = this.content.assigneesIDs.map((el) => this.populateUser(el));
                    this.selectedAssignees = populatedAssignees.filter((el) => el != null);
                    this.content.startDate = this.content.startDate.toDate();
                    break;
                case 'new':
                    this.content = {
                        title: null,
                        startDate: null,
                        done: false,
                        completedElementIDs: [],
                        allElementIDs: [],
                    };
                    break;
            }

            console.log(this.content);
        }
    };
</script>

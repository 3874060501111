<template>
    <div class="flex flex--column flex--center__vert width--100 height--100">
        <div class="dashboard--header">
            <ActionButton @onClick="$router.push('/reports')" icon="arrow-back"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow">
            <div class="flex--column width--100">
                <div class="flex flex--column" v-if="$route.params.type !== 'new'">
                    <label class="input--label" for="reports_status">Status</label>
                    <input :value="title" class="input--text mar--bottom__2" disabled id="reports_status"/>
                    <div class="input--label">Kunde</div>
                    <v-select :clearable="false" :options="clients" :value="populateClient(content.clientID)" class="input--selection width--100 mar--bottom__2"
                              disabled
                              label="name"/>
                    <div class="input--label">Objekt</div>
                    <v-select :clearable="false" :options="objects" :value="populateObject(content.objectID)" class="input--selection width--100 mar--bottom__2"
                              disabled
                              label="name"/>
                </div>
                <div class="flex flex--column" v-else>
                    <div class="input--label">Kunde</div>
                    <v-select :clearable="false" :options="clients" class="input--selection width--100 mar--bottom__2" label="name"
                              v-model="selectedClientFilter"/>
                    <div class="input--label">Objekt</div>
                    <v-select :clearable="false" :disabled="!selectedClientFilter" :options="objects" class="input--selection width--100 mar--bottom__2"
                              label="name" v-model="selectedObjectFilter"/>

                </div>
                <div class="input--label">Startdatum</div>
                <!--                <input id="job_date" class="input&#45;&#45;text mar&#45;&#45;bottom__2" disabled :value="content.startDate | dateFormat"/>-->
                <datepicker :disabled="$route.params.type !== 'new'"
                            class="input--selection width--100 mar--bottom__2" :format="dateFormatter" :language="de"
                            v-model="content.startDate"/>

            </div>
            <div class="mar--bottom__1" v-if="$route.params.type === 'new'">
                <AccentButton :loading="loading" @onClick="$route.params.type === 'new' ? create() : update()"
                              :title="$route.params.type === 'new' ? 'Erstellen' : 'Herunterladen'"/>
            </div>
<!--            <a target="_blank" class="noDec" :href="'https://api.ismberlin.app/reports/' + this.content.id" v-if="$route.params.type !== 'new' && content.status === 'success'">-->
<!--                <AccentButton :loading="loading"-->
<!--                              :title="'Herunterladen'"/>-->
<!--            </a>-->
            <AccentButton :loading="loading" @onClick="create"
                          :title="'Herunterladen'"/>
        </div>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '../../components/Buttons/ActionButton';
    import AccentButton from '../../components/Buttons/AccentButton';
    import _ from 'lodash';
    import moment from "moment";
    import { de } from 'vuejs-datepicker/dist/locale';

    export default {
        name: 'ReportDetails',
        components: {AccentButton, ActionButton, vSelect},
        data() {
            return {
                showDeleteConfirmation: false,
                type: null,
                content: {},
                loading: false,
                selectedElements: [],
                de: de,
            };
        },
        filters: {
            dateFormat(val) {
                if (!val) return null;
                return moment(val.toDate()).format('DD.MM.yyyy');
            },
        },
        computed: {
            options() {
                if (this.$route.params.type === 'new') {
                    return [{
                        label: 'Wartungsauftrag',
                        value: 'maintenance',
                    }, {
                        label: 'Reparaturauftrag',
                        value: 'repair',
                    }];
                } else {
                    return [];
                }
            },

            title() {
                switch (this.content.status) {
                    case 'success':
                        return 'Fertig';
                    case 'processing':
                        return 'In Arbeit...';
                    default:
                        return 'Fehlgeschlagen';
                }
            },
            selectedClientFilter: {
                get() {
                    return this.$store.getters.selectedClientFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedClientFilter', val);
                }
            },
            selectedObjectFilter: {
                get() {
                    return this.$store.getters.selectedObjectFilter;
                },
                set(val) {
                    this.$store.commit('setSelectedObjectFilter', val);
                }
            },
            jobs() {
                return this.$store.getters.jobs;
            },
            clients() {
                return this.$store.getters.clients;
            },
            objects() {
                return this.$store.getters.objects;
            },
            reports() {
                return this.$store.getters.reports;
            },
        },
        methods: {
            dateFormatter(val) {
                return moment(val).format('DD.MM.yyyy');
            },
            populateClient(val) {
                const client = _.cloneDeep(this.clients.find((el) => el.id === val));
                return client ?? null;
            },
            populateObject(val) {
                const client = _.cloneDeep(this.objects.find((el) => el.id === val));
                return client ?? null;
            },
            async create() {
                this.loading = true;
                await this.$store.dispatch('createReport', this.content.startDate);
            },
            async update() {
                this.loading = true;
                await fetch('https://api.ismberlin.app/reports/' + this.content.id);
                // await this.$store.dispatch('downloadReport', this.content.id);
            },
            async deleteItem(confirmed) {
                if (!confirmed) {
                    this.showDeleteConfirmation = true;
                } else {
                    await this.$store.dispatch('deleteUser', {
                        id: this.content._id,
                    });
                    this.$router.go(-1);
                    this.showDeleteConfirmation = false;
                }
            }
        },
        watch: {
            selectedClientFilter() {
                this.$store.dispatch('fetchObjects', false);
            },
        },
        async mounted() {
            if (this.$route.params.type !== 'new') {
                this.content = _.cloneDeep(this.reports.find((el) => el.id === this.$route.params.id));
                this.selectedClientFilter = this.populateClient(this.content.clientID);
                this.$store.dispatch('fetchObjects', false);
                this.content.startDate = this.content.startDate.toDate();
            } else {
                this.content = {
                    startDate: null,
                }
            }

            console.log(this.content);
        }
    };
</script>

<template>
    <div class="flex flex--column flex--center__vert width--100 height--100">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/overview')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow">
            <div class="input--label">Typ</div>
            <v-select :options="options" :clearable="false" v-model="type" :disabled="$route.params.type !== 'new'"
                      class="input--selection width--100 mar--bottom__2"/>

            <!-- Mitarbeiter Formular -->
            <div class="flex--column width--100" v-if="type === 'Mitarbeiter'">
                <div class="flex flex--column width--100" v-if="$route.params.type === 'new'">
                    <label for="user_email" class="input--label">Email</label>
                    <input id="user_email" class="input--text mar--bottom__2" v-model="content.email"/>
                    <label for="user_password" class="input--label">Passwort</label>
                    <input id="user_password" class="input--text mar--bottom__2" v-model="content.password"/>
                </div>
                <label for="user_firstname" class="input--label">Vorname</label>
                <input id="user_firstname" class="input--text mar--bottom__2" v-model="content.firstName"/>
                <label for="user_lastname" class="input--label">Nachname</label>
                <input id="user_lastname" class="input--text mar--bottom__2" v-model="content.lastName"/>
                <div class="input--label">Administrator</div>
                <div class="flex">
                    <input id="user_block" type="checkbox" v-model="content.roles.admin" class="input--checkbox"/>
                    <label for="user_block" class="flex flex--center">
                        <ion-icon name="checkmark" class="font--s3 color--primary flex flex--center"/>
                    </label>
                </div>
            </div>

            <!-- Kunden Formular -->
            <div class="flex--column width--100" v-if="type === 'Kunde'">
                <label for="client_name" class="input--label">Name</label>
                <input id="client_name" class="input--text mar--bottom__2" v-model="content.name"/>
            </div>

            <!-- Objekt Formular -->
            <div class="flex--column width--100" v-if="type === 'Objekt'">
                <div class="flex flex--column" v-if="$route.params.type === 'new'">
                    <div class="input--label">Kunde</div>
                    <v-select :options="clients" :clearable="false" placeholder="Kunde" label="name" v-model="selectedClient" class="input--selection width--100 mar--bottom__2"/>
                </div>
                <label for="object_name" class="input--label">Name</label>
                <input id="object_name" class="input--text mar--bottom__2" v-model="content.name"/>
                <label for="object_country" class="input--label">Land</label>
                <input id="object_country" class="input--text mar--bottom__2" v-model="content.address.country"/>
                <label for="object_city" class="input--label">Stadt</label>
                <input id="object_city" class="input--text mar--bottom__2" v-model="content.address.city"/>
                <label for="object_code" class="input--label">Postleitzahl</label>
                <input id="object_code" class="input--text mar--bottom__2" v-model="content.address.postalCode"/>
                <label for="object_street" class="input--label">Straße</label>
                <input id="object_street" class="input--text mar--bottom__2" v-model="content.address.street"/>
                <label for="object_number" class="input--label">Hausnummer</label>
                <input id="object_number" class="input--text mar--bottom__2" v-model="content.address.streetNumber"/>
            </div>

            <!-- Objekt Formular -->
            <div class="flex--column width--100" v-if="type === 'Element'">
                <label for="el_floor" class="input--label">Etage</label>
                <input id="el_floor" class="input--text mar--bottom__2" v-model="content.floor"/>
                <label for="el_room" class="input--label">Raum</label>
                <input id="el_room" class="input--text mar--bottom__2" v-model="content.room"/>
                <label for="el_position" class="input--label">Position v. links</label>
                <input id="el_position" class="input--text mar--bottom__2" v-model="content.position"/>
                <label for="el_element" class="input--label">Element</label>
                <input id="el_element" class="input--text mar--bottom__2" v-model="content.element"/>
                <label for="el_type" class="input--label">Typ</label>
                <input id="el_type" class="input--text mar--bottom__2" v-model="content.type"/>
                <label for="el_material" class="input--label">Material</label>
                <input id="el_material" class="input--text mar--bottom__2" v-model="content.material"/>
                <label for="el_manufacturer" class="input--label">Hersteller Beschläge</label>
                <input id="el_manufacturer" class="input--text mar--bottom__2" v-model="content.manufacturer"/>
                <label for="el_status" class="input--label">Aktueller Status</label>
                <input id="el_status" class="input--text mar--bottom__2" disabled :value="content.healthy ? 'Mangelfrei' : 'Beschädigt'"/>
                <label for="el_date" class="input--label">Letzte Wartung</label>
                <input id="el_date" class="input--text mar--bottom__2" disabled :value="moment(content.lastMaintainedAt.toDate()).format('DD.MM.yyyy')"/>
            </div>
            <div class="mar--bottom__1">
                <AccentButton title="Speichern" :loading="loading" @onClick="$route.params.type === 'new' ? create() : update()"/>
            </div>
            <div class="flex flex--end__hor" v-if="type !== 'Mitarbeiter'">
                <AccentButton danger="true" title="Löschen" v-if="content" @onClick="deleteItem(false)"/>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <Modal title="Achtung! Wirklich löschen?" v-if="showDeleteConfirmation" @toggle="showDeleteConfirmation = false">
                <div class="flex flex--column pad--2 width--100 height--100">
                    <div class="mar--bottom__1 font--s16 pad--1">
                        Durch das Löschen werden die Daten unwiederruflich gelöscht.
                    </div>
                    <div class="width--100 flex pad--1">
                        <AccentButton title="Nein" class="width--100" @onClick="showDeleteConfirmation = false" />
                        <AccentButton danger="true" title="Löschen" @onClick="deleteItem(true)"/>
                    </div>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '../../components/Buttons/ActionButton';
    import AccentButton from '../../components/Buttons/AccentButton';
    import Modal from "../../components/Modals/Modal";
    import _ from 'lodash';
    export default {
        name: 'OverviewCreate',
        components: {Modal, AccentButton, ActionButton, vSelect},
        data() {
            return {
                showDeleteConfirmation: false,
                type: null,
                content: {},
                loading: false,
                selectedClient: null,
            };
        },
        computed: {
            options() {
                if (this.$route.params.type === 'new') {
                    return ['Mitarbeiter', 'Kunde', 'Objekt'];
                } else {
                    return [];
                }
            },
            users() {
                return this.$store.getters.users;
            },
            clients() {
                return this.$store.getters.clients;
            },
            objects() {
                return this.$store.getters.objects;
            },
            elements() {
                return this.$store.getters.elements;
            },
        },
        watch: {
            type(newVal) {
                if (this.$route.params.type !== 'new') return;
                switch (newVal) {
                    case 'Mitarbeiter':
                        this.content = {
                            'firstName': null,
                            'lastName': null,
                            'roles': {
                                'admin': false,
                                'worker': true,
                            }
                        };
                        break;
                    case 'Kunde':
                        this.content = {
                            'name': null,
                        };
                        break;
                    case 'Objekt':
                        this.content = {
                            'name': null,
                            'address': {
                                'city': null,
                                'country': null,
                                'postalCode': null,
                                'street': null,
                                'streetNumber': null
                            }
                        };
                        break;
                    case 'Element':
                        this.content = {
                            'floor': null,
                            'room': null,
                            'position': null,
                            'element': null,
                            'type': null,
                            'material': null,
                            'manufacturer': null,
                            };
                    break;
                }
            }
        },
        methods: {
            async create() {
                console.log('Create!');
                this.loading = true;
                switch (this.type) {
                    case 'Mitarbeiter':
                        await this.$store.dispatch('createUser', this.content).catch(() => this.loading = false);
                        break;
                    case 'Kunde':
                        await this.$store.dispatch('createClient', this.content).catch(() => this.loading = false);
                        break;
                    case 'Objekt':
                        await this.$store.dispatch('createObject', {
                            'object': this.content,
                            'client': this.selectedClient,
                        }).catch(() => this.loading = false);
                        break;
                }
            },
            async update() {
                this.loading = true;
                switch (this.type) {
                    case 'Mitarbeiter':
                        await this.$store.dispatch('editUser', this.content);
                        break;
                    case 'Kunde':
                        await this.$store.dispatch('editClient', this.content);
                        break;
                    case 'Objekt':
                        await this.$store.dispatch('editObject', this.content);
                        break;
                    case 'Element':
                        await this.$store.dispatch('editElement', this.content);
                        break;
                }
            },
            async deleteItem(confirmed) {
                if (!confirmed) {
                    this.showDeleteConfirmation = true;
                    return;
                }
                switch (this.type) {
                    case 'Kunde':
                        await this.$store.dispatch('deleteClient', this.content);
                        this.showDeleteConfirmation = false;
                        break;
                    case 'Objekt':
                        await this.$store.dispatch('deleteObject', this.content);
                        this.showDeleteConfirmation = false;
                        break;
                    case 'Element':
                        await this.$store.dispatch('deleteElement', this.content);
                        this.showDeleteConfirmation = false;
                        break;
                }
            }
        },
        async mounted() {
            switch (this.$route.params.type) {
                case 'user':
                    this.type = 'Mitarbeiter';
                    this.content = _.cloneDeep(this.users.find((el) => el.id === this.$route.params.id));
                    break;
                case 'client':
                    this.type = 'Kunde';
                    this.content = _.cloneDeep(this.clients.find((el) => el.id === this.$route.params.id));
                    break;
                case 'object':
                    this.type = 'Objekt';
                    this.content = _.cloneDeep(this.objects.find((el) => el.id === this.$route.params.id));
                    break;
                case 'element':
                    this.type = 'Element';
                    this.content = _.cloneDeep(this.elements.find((el) => el.id === this.$route.params.id));
                    break;

            }

            if (!this.content) {
                this.$router.push('/overview');
            }
            console.log(this.content);
        }
    };
</script>

<template>
    <div class="grid width--100 height--100__abs dashboard bgColor--greyLight relative overflowHidden">
        <div class="bgColor--white height--100__abs dashboard--nav__wrapper" :class="{'dashboard--nav__out': navOut}">
            <div class="height--100 shadow--light grid dashboard--nav relative">
                <div class="pad--2 flex flex--center height--100 width--100 relative border--bottom__light">
                    <img src="../assets/images/Logo.jpg" class="width--100" />
                    <div class="dashboard--nav__trigger flex--center bgColor--white shadow--light absolute"
                         @click="navOut ? navOut = false : navOut = true">
                        <button class="burgerMenu burgerMenu--spin noDec" type="button" :class="{'is-active': !navOut}" @click="!navOut ? navOut = false : navOut = true">
                          <span class="burgerMenu-box">
                            <span class="burgerMenu-inner" />
                          </span>
                        </button>
                    </div>
                </div>
                <div class="pad--1 flex flex--center width--100 relativ border--bottom__light">
                    <ion-icon name="person" class="font--s14 mar--right__1"/>
                    <div class="font--s14 font--w700">{{ userProfile ? userProfile.firstName : null }} {{ userProfile ? userProfile.lastName : null}}</div>
                </div>
                <div class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item">
                    <div class="flex flex--center pad--1 b-radius--5 width--100 transition"
                         :class="{'bgColor--secondary': tab[1] === '', 'color--white': tab[1] === ''}"
                         @click="tab[1] === '' ? null : $router.push('/')">
                        Startseite
                    </div>
                </div>
                <div class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item">
                    <div class="flex flex--center pad--1 b-radius--5 width--100 transition"
                         :class="{'bgColor--secondary': tab[1] === 'overview', 'color--white': tab[1] === 'overview'}"
                         @click="tab[1] === 'overview' ? null : $router.push('/overview')">
                        Übersicht
                    </div>
                </div>
                <div class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item">
                    <div class="flex flex--center pad--1 b-radius--5 width--100 transition"
                         :class="{'bgColor--secondary': tab[1] === 'jobs', 'color--white': tab[1] === 'jobs'}"
                         @click="tab[1] === 'jobs' ? null : $router.push('/jobs')">
                        Aufträge
                    </div>
                </div>
<!--                <div class="flex flex&#45;&#45;center font&#45;&#45;s14 font&#45;&#45;w700 pad&#45;&#45;05 clickable dashboard&#45;&#45;nav__item">-->
<!--                    <div class="flex flex&#45;&#45;center pad&#45;&#45;1 b-radius&#45;&#45;5 width&#45;&#45;100 transition"-->
<!--                         :class="{'bgColor&#45;&#45;secondary': tab[1] === 'reports', 'color&#45;&#45;white': tab[1] === 'reports'}"-->
<!--                         @click="tab[1] === 'reports' ? null : $router.push('/reports')">-->
<!--                        Berichte-->
<!--                    </div>-->
<!--                </div>-->
                <div class="border--bottom__light" />
                <div class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item" @click="logout">
                    <div class="flex flex--center pad--1 b-radius--5 width--100 transition">
                        Abmelden
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="dashboard--nav__backdrop absolute" v-if="!navOut" @click="navOut = true"/>
        </transition>
        <div class="overflowAuto">
            <div class="dashboard--nav__placeholder" />
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'DashboardLoader',
        data() {
            return {
                navOut: true,
            };
        },
        computed: {
            tab() {
                return this.$route.path.split('/');
            },
            userProfile() {
                return this.$store.getters.userProfile;
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout');
            },
        },
    };
</script>

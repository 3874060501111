import LandingView from '@/views/LandingView';
import OverviewView from '@/views/1_Overview/OverviewView';
import ReportsView from '@/views/3_Reports/ReportsView';
import ReportDetails from '@/views/3_Reports/ReportDetails';
import OverviewCreate from '@/views/1_Overview/OverviewCreate';
import AuthenticationView from "@/views/AuthenticationView";
import DashboardLoader from "@/views/DashboardLoader";
import JobsView from "@/views/2_Jobs/JobsView";
import JobDetails from "@/views/2_Jobs/JobDetails";

export default [
    {
        path: '/login',
        component: AuthenticationView,
    },
    {
        path: '/',
        component: DashboardLoader,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                component: LandingView,
            },
            {
                path: 'overview',
                component: OverviewView,
            },
            {
                path: 'overview/:type/:id',
                component: OverviewCreate,
            },
            {
                path: 'jobs',
                component: JobsView,
            },
            {
                path: 'jobs/:type/:id',
                component: JobDetails,
            },
            {
                path: 'reports',
                component: ReportsView,
            },
            {
                path: 'reports/:type/:id',
                component: ReportDetails,
            },
            {
                path: 'settings',
            },
        ],
    },
]
